<template>
  <div class="i-layout-navbar">
    <!-- 折叠左侧菜单 -->
    <Hamburger class="i-layout-navbar-trigger" />
    <!-- 刷新当前页面 -->
    <RefreshPage class="i-layout-navbar-trigger" />
    <!-- 面包屑导航 -->
    <Breadcrumb v-if="!isMobile" />
    <!-- 右侧菜单 -->
    <div class="i-layout-navbar-right-menu">
      <!-- 用于筛选首页数据展示 -->
      <Select v-if="isAdmin && isHome" @on-change="changeCity" v-model="newCityId" style="width: 200px">
        <Option v-for="item in cityList" :value="item.id" :key="item.id">{{ item.name }}</Option>
      </Select>
      <!-- 页面全屏 -->
      <Fullscreen v-if="!isMobile" class="i-layout-navbar-trigger" />
      <!-- 系统通知 -->
      <!-- <Notify class="i-layout-navbar-trigger" /> -->
      <!-- 用户登录信息 -->
      <UserAvatar class="i-layout-navbar-trigger" />
      <!-- 系统设置 -->
      <Settings class="i-layout-navbar-trigger" />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { getCitiesList } from '@/api/modules/housing'
import { computed, ref, watch } from 'vue'
import useAppStore from '@/store/modules/app'
import Hamburger from '../Hamburger/index.vue'
import RefreshPage from '../RefreshPage/index.vue'
import Breadcrumb from '../Breadcrumb/index.vue'
import Fullscreen from '../Fullscreen/index.vue'
import Notify from '../Notify/index.vue'
import useUserStore from '@/store/modules/user'
import UserAvatar from '../UserAvatar/index.vue'
import Settings from '../Settings/index.vue'
//仓库实例化
const appStore = useAppStore()
//所有城市列表
const cityList = ref([])
const newCityId = ref('0')
//当前页面是否为首页
let isHome = ref(false)
//设备类型
const isMobile = computed(() => {
  return appStore.device === 'mobile'
})
//判断useUserStore().roles中是否有20和21
const isAdmin = computed(() => {
  return useUserStore().roles.includes(20) || useUserStore().roles.includes(21) || !useUserStore().roles.length
})
//获取所有城市列表
const getCityList = async () => {
  const data = await getCitiesList()
  data.push({ name: '集团总数据', id: '0' })
  cityList.value = data
}
//获取当前页面路由地址
const route = useRoute()
//城市变化
const changeCity = (val) => {
  //修改缓存中useUserStore里state中newCityId的值
  useUserStore().newCityId = val
}
getCityList()
watch(
  () => route.path,
  (news) => {
    isHome.value = news == '/admin/home'
  },
  { immediate: true }
)
</script>

<style scoped lang="less">
.i-layout-navbar {
  height: 64px;
  line-height: 64px;
  box-sizing: border-box;
  position: relative;
  background: #fff;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  // trigger鼠标滑过样式
  &-trigger {
    transition: background 0.3s ease-in-out;
    -webkit-tap-highlight-color: transparent;
    &:hover {
      background: rgba(0, 0, 0, 0.035);
    }
  }

  // 右侧按钮菜单样式
  &-right-menu {
    float: right;
    height: 100%;
    line-height: 64px;
  }
}
</style>
