import request from '../request'

/**
 * 获取员工列表
 * @returns {Promise} Promise 对象
 */
export function getStaffList(data) {
  return request({
    url: '/user/employee/list',
    method: 'get',
    params: data
  })
}
/**
 * 获取某公司员工列表
 * @returns {Promise} Promise 对象
 */
export function getEmployeeApi(id, params) {
  return request({
    url: `/user/employee/${id}`,
    method: 'get',
    params
  })
}

/**
 * 获取城市列表
 * @returns {Promise} Promise 对象
 */
export function getCitiesList() {
  return request({
    url: '/resource/city/list',
    method: 'get'
  })
}

/**
 * 获取省份城市区域列表
 * @returns {Promise} Promise 对象
 */
export function getAreaList() {
  return request({
    url: '/resource/city/area/list',
    method: 'get'
  })
}

/**
 * 获取房源列表
 * @returns {Promise} Promise 对象
 */
export function getRoomsList(data) {
  return request({
    url: '/resource/room/list',
    method: 'get',
    params: data
  })
}

/**
 * 房源导出
 * @returns {Promise} Promise 对象
 */
export function skyHouseExport(data) {
  return request({
    url: '/resource/house/export',
    method: 'get',
    params: data
  })
}

/**
 * 房源详情
 * @returns {Promise} Promise 对象
 */
export function getHouseInof(id, data) {
  return request({
    url: `/resource/house/${id}/detail`,
    method: 'get',
    params: data
  })
}

/**
 * 删除房子
 * @returns {Promise} Promise 对象
 */
export function delHouse(d_type, resource_id) {
  return request({
    url: `/resource/house/${d_type}/${resource_id}`,
    method: 'delete'
  })
}

/**
 * 房源统计
 * @param {params} params 对象
 * @returns {Promise} Promise 对象
 */
export function getHouseStatApi(params) {
  return request({
    url: `/resource/house/stat`,
    method: 'get',
    params
  })
}

/**
 * 新增房源
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function createHouse(data) {
  return request({
    url: '/resource/resource',
    method: 'post',
    data: data
  })
}

/**
 * 新增楼栋
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function addSingle(data) {
  return request({
    url: '/resource/resource/single',
    method: 'post',
    data: data
  })
}

/**
 * 获取房间设施列表
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getFacility(data) {
  return request({
    url: '/resource/house/facility',
    method: 'get',
    params: data
  })
}

/**
 * 编辑房源
 * @param {number} houseId
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function editHouse(houseId, data) {
  return request({
    url: `/resource/resource/${houseId}`,
    method: 'put',
    data: data
  })
}

/**
 * 批量修改房源管家
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function changeHousekeepers(data) {
  return request({
    url: '/resource/house/housekeeper/change',
    method: 'put',
    data: data
  })
}

/**
 * 编辑房源上下架
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function editRoomStatus(data) {
  return request({
    url: '/resource/room/status',
    method: 'put',
    data: data
  })
}

/**
 * 公司列表
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getCompanyList(data) {
  return request({
    url: '/user/company/list',
    method: 'get',
    params: data
  })
}
//  新增公司
export function createCompany(data) {
  return request({
    url: '/user/company',
    method: 'post',
    data: data
  })
}

// 编辑公司
export function editCompany(id, data) {
  return request({
    url: `/user/company/${id}`,
    method: 'put',
    data: data
  })
}

// 删除公司
export function delCompany(id) {
  return request({
    url: `/user/company/${id}`,
    method: 'delete'
  })
}

/**
 * 标签列表
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getTagList(data) {
  return request({
    url: '/resource/tag/list',
    method: 'get',
    params: data
  })
}

/**
 * 新增标签
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function createTag(data) {
  return request({
    url: '/resource/tag',
    method: 'post',
    data: data
  })
}

/**
 * 编辑标签
 * @param {number} tagId
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function editTag(tagId, data) {
  //
  return request({
    url: `/resource/tag/${tagId}`,
    method: 'put',
    data: data
  })
}

/**
 * 删除标签
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function deleteTag(data) {
  return request({
    url: `/resource/tag/${tagId}`,
    method: 'delete',
    data: data
  })
}

/**
 * 批量修改房源管家
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function changeHousekeeper(data) {
  return request({
    url: '/resource/house/housekeeper/change',
    method: 'put',
    data: data
  })
}
/**
 * 房态图列表
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getHouseList(data) {
  return request({
    url: '/resource/house/list',
    method: 'get',
    params: data
  })
}

/**
 * 水表记录
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getRecordsList(room_id, params) {
  return request({
    url: `/resource/room/meter/${room_id}/records`,
    method: 'get',
    params
  })
}

/**
 * 谈价记录列表
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getBargainingList(params) {
  return request({
    url: '/resource/bargaining/list',
    method: 'get',
    params
  })
}

/**
 * 编辑谈价记录
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function editBargaining(record_id, data) {
  return request({
    url: `/resource/bargaining/${record_id}`,
    method: 'put',
    data
  })
}

/**
 *  查看房子及其对应的房间
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getHouseRoomList(data) {
  return request({
    url: '/resource/house/room',
    method: 'get',
    itemType: 'bz',
    params: data
  })
}

/**
 *  查看房间历史租客列表
 * @returns {Promise} Promise 对象
 */
export function getHisTenantList(id) {
  return request({
    url: `/housekeeper/room/${id}/his_tenant`,
    itemType: 'hetong',
    method: 'get'
  })
}

/**
 *  查看锁房记录
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getLockRoomRecord(params) {
  return request({
    url: '/resource/house/book/fee',
    method: 'get',
    params
  })
}

/**
 *  修改锁房租金
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function putLockRoomRent(data) {
  return request({
    url: '/resource/house/book/fee',
    method: 'put',
    data
  })
}

/**
 *  重置按天交租
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function rentByDay(data) {
  return request({
    url: '/resource/money/rent_by_day',
    method: 'put',
    data
  })
}

/**
 *  空房间指导价
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function getGuidePrice(record_id) {
  return request({
    url: `/resource/bargaining/${record_id}/price`,
    method: 'get'
  })
}

/**
 *  编辑二级资产包
 * @param {number} id - 公司id
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function editGroupApi(id, data) {
  return request({
    url: `/user/company/group/${id}`,
    method: 'put',
    data
  })
}
/**
 *  新增二级资产包
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function addGroupApi(data) {
  return request({
    url: `/user/company/group/create`,
    method: 'post',
    data
  })
}
/**
 *  删除二级资产包
 * @param {number} id - 公司id
 * @param {Object} data
 * @returns {Promise} Promise 对象
 */
export function deleteGroupApi(id, data) {
  return request({
    url: `/user/company/group/${id}`,
    method: 'delete',
    data
  })
}
